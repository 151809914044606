<template>
  <v-app>
    <body>
        <v-container class="white-bg mt-32px">
          <div class="text-xs-center section">
              <div class="ct-mw navy-text">
                <v-img
                  :src="require('@/assets/image/achieve-plus-favicon.svg')"
                  contain
                  height="80"
                />
                <br/>
                <span class="subtitle"><strong>ชำระเงินสำเร็จแล้ว</strong></span><br/>
                <span class="caption">
                  สามารถตรวจสอบได้ที่ Profile > Payment > Order History</span>
                <br/><br/>
                ขอบคุณที่ใช้บริการ Achieve+ <br/>
                สอบถามเพิ่มเติม ติดต่อ billing@achieve.plus<br/>
              </div>
              <br/>
              <div class="text-xs-center">
                <v-flex xs12>
                  <router-link to="/profile/index">
                    <v-btn
                    dark>DONE</v-btn>
                  </router-link>
                </v-flex>
              </div>
          </div>
        </v-container>
    </body>
  </v-app>
</template>

<script>
import payment from '../../library/payment';

export default {
  mounted() {
    const { id, orderCheckout } = this.$route.params;
    if (!id) {
      this.$route.go(-1);
    }
    payment.createOrder(orderCheckout);
    this.$store.dispatch('setLoading', true);
    const { uid } = this.$store.getters.user;
    const { workshopList } = this.$store.getters.profile;
    const data = {
      workshopList: workshopList ? [...workshopList, id] : [id],
    };
    this.$store.dispatch('setProfile', { uid, data })
      .then(() => {
        this.$store.dispatch('setLoading', false);
      })
      .catch((e) => {
        console.error(e);
      });
  },
};
</script>


<style lang="sass" scoped>
.ct-mw
  max-width: 960px
  margin: auto
.white-bg
  max-width: 1100px
  margin: auto
.section
  padding: 16px
  border-color: #DBDBDB
  border-radius: 5px
  border-style: solid
  margin: auto

.detail-text
  font-weight: 200

.bg-grey
  background-color: #DBDBDB
  padding-top: 4px
  padding-bottom: 4px

.mw-divider
  max-width: 300px
  margin-left: auto
  margin-right: auto
  margin-top: -8px

.mw-method
  max-width: 200px
  margin-top: 16px
  margin-bottom: 16px
.mt-32px
  margin-top: 32px
</style>
